.admin-main {
  /* padding: 5%; */
  padding: 2rem;
  max-width: 767px;
  margin: 2rem auto;
  font-weight: lighter;
  box-shadow: 0px 0px 35px -20px #8187ad;
  border-radius: 14px;
}
#select {
  -webkit-appearance: none;
  -moz-appearance: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: groove;
  margin-bottom: 50px;
}
.titles {
  max-height: 100%;
}
.titles_backBtn {
  font-weight: 500;
  font-size: 1rem;
}
.titless {
  background-color: aliceblue;
}
.submit_btn {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 200px;
  width: 100%;
  background-color: black;
  color: white;
  cursor: pointer;
  min-height: 44px;
  border-radius: 8px;
}
/* 
#check1{
    display: flex;
   justify-content: space-between;
} */
Button {
  margin-top: 10px;
}
#profilepicc {
  height: 200px;
  width: 200px;
  object-fit: cover;
  border: 1px solid grey;
}
#photo {
  height: 200px;
  width: 200px;
  object-fit: cover;
}
#file {
  display: none;
}
.profile {
  height: 200px;
  width: 200px;
  position: absolute;
  /* border-radius: 50%; */
  overflow: hidden;
  border: 1px solid grey;
}
#uploadBtn {
  height: 40px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  background: rgba(0, 0, 0, 0.7);
  color: wheat;
  line-height: 30px;
  font-family: sans-serif;
  font-size: 15px;
  cursor: pointer;
}
