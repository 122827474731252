.tag-button {
    display: inline-block;
    border: 1px solid #b4b4b4;
    border-radius: 5px;
    padding: 0px 0px 0px 10px;
    margin: 10px 10px 0px 0px;
}
.tag-button span {
    color: #000;
    font-weight: 400;
}