@import "~antd/dist/antd.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  height: auto;
}

.main-container {
  display: flex;
  position: relative;
}
main {
  padding: 10px;
  margin-top: 100px;
}

.title {
  /* font-size: 3rem; */
  margin-left: 80px;
  width: 900px;
  height: calc(100vh - 90px);
  padding: 20%;
  background-color: lightblue;
}
.tables {
  margin-left: 90px;
  width: 1000px;
}

/* Sidebar */
.sidebar {
  background: #000;
  color: white;
  height: calc(100vh - 90px);
  /* height:100vh; */
  margin-top: 0;
  overflow-y: auto;
  position: fixed;
  z-index: 100;
}

.top_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px;
}
.logo {
  font-size: 18px;
  line-height: 0;
}
.bars {
  width: 30px;
}
.hide {
  display: none;
}

.search {
  display: flex;
  align-items: center;
  margin: 10px 0;
  height: 30px;
  padding: 10px;
}
.search input {
  border: none;
  margin-left: 10px;
  border-radius: 5px;
  background: rgb(238, 238, 238);

  color: white;
}

.routes {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.link {
  display: flex;
  color: white;
  gap: 10px;
  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.link:hover {
  border-right: 4px solid white;
  background: rgb(45, 51, 89);
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.active {
  border-right: 4px solid white;
  background: rgb(45, 51, 89);
}
.link_text {
  white-space: nowrap;
  font-size: 15px;
}

.menu {
  display: flex;
  color: white;
  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  justify-content: space-between;
}
.menu_item {
  display: flex;
  gap: 10px;
}
.menu_container {
  display: flex;
  flex-direction: column;
}
.menu_container .link {
  padding-left: 20px;
  border-bottom: #fff 0.5px solid;
}

.table-outer header {
  padding-left: 16px !important;
}

.offer-modal .modal-content {
  border-radius: 120px 0;
}

.offer-modal .modal-body {
  padding: unset;
}

.offer-modal .offer-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 120px;
}

.offerModalOuter {
  display: flex;
}

.offer-logo img {
  max-width: 100%;
  width: 200px;
  height: auto;
}

.offer-right-text h3 {
  color: #454545;
  text-align: center;
  font-family: " Playfair Display";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
}

.offer-right-text input {
  width: 100%;
  border-radius: 7px;
  background: #f6f3ee;
  font-size: 14px;
  height: 45px;
}

.offer-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.update-btn {
  /* min-width: 250px; */
}

.decline-btn {
  background: unset !important;
  outline: unset !important;
  box-shadow: unset !important;
  border: unset;
  color: #454545 !important;
  font-family: "Brown";
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  text-decoration-line: underline;
  text-transform: capitalize;
}

.offer-right-text h1 {
  color: #000;
  font-family: "Brown";
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.error {
  color: red;
}

.close-btnn {
  background: transparent !important;
  border: none !important;
  outline: none !important;
  color: #000 !important;
  position: absolute;
  top: 10px;
  right: 10px;
  margin: 0;
}

.content-btn {
  font-size: 13px;
  border-radius: 2px 25px;
  background: #be8826 !important;
  box-shadow: 0px 0.7699999809265137px 9.210000038146973px 0px
      rgba(0, 0, 0, 0.03),
    0px 3.6500000953674316px 16.31999969482422px 0px rgba(0, 0, 0, 0.03),
    0px 6.519999980926514px 27.020000457763672px 0px rgba(0, 0, 0, 0.04),
    0px 12.34000015258789px 32.869998931884766px 0px rgba(0, 0, 0, 0.04),
    0px 19.780000686645508px 29.420000076293945px 0px rgba(0, 0, 0, 0.1),
    0px 20px 49px 0px rgba(0, 0, 0, 0.13);
  border: unset;
  min-width: 250px;
  max-width: 315px;
  height: 45px;
  display: flex;
  align-items: center;
  font-family: "Brown";
  justify-content: center;
  text-transform: uppercase;
  transition: all 0.4s ease-in-out;
}

.content-btn.btn:active {
  background: #454545;
}

.content-btn:hover {
  background: #000;
}

.image-area {
  position: relative;
  margin-top: 15px;
}
.image-area img {
  width: 110px;
  height: 50px;
}
img[src=""] {
  display: none;
}
/* .remove-image {
  color: rgb(0, 0, 0);
  font-size: 20px;
  position: absolute;
  top: -12px;
  left: 38px;
  width: 6%;
  line-height: 17px;
  height: 22px;
  border: 1px solid #000;
  border-radius: 100%;
  text-align: center;
  display: "inline";
  color: "#000";
  font-size: "20px";
} */
